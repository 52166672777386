<template lang="pug">
.home.flex
  .map-sidebar(:class='{ opened: menuOpened }')
    .tabs-container
      .tabs-header
        a.tab-item(@click='tab = 1' :class='{ active: tab === 1}') Equipos
        a.tab-item(@click='tab = 2' :class='{ active: tab === 2}') Zonas
        a.tab-item(@click='tab = 3' :class='{ active: tab === 3}') Sitios
        button.tab-right-item.close-button(type='button' @click='toggleMapMenu')
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      .tab-body
        devices-list(v-show='tab === 1' allow-check)
        zones-list(v-show='tab === 2' allow-check)
        locations-list(v-show='tab === 3' allow-check)
  .body.flex-1
    main-map(
      v-if='mountMap'
      @device-marker-clicked='deviceMarkerClicked'
      @user-move-started='userMoveStarted'
    )
    device-detail
</template>

<script>
import { mapActions } from 'vuex'
import DevicesList from '@/components/home/DevicesList'
import ZonesList from '@/components/home/ZonesList'
import LocationsList from '@/components/home/LocationsList'
import DeviceDetail from '@/components/devices/DeviceDetail'
import MainMap from '@/components/home/MainMap'
import { navbarStatusChanged } from '@/utils/events'

export default {
  name: 'home',
  components: { DevicesList, ZonesList, LocationsList, DeviceDetail, MainMap },
  data () {
    return {
      tab: 1,
      menuOpened: false,
      eventListeners: [],
      mountMap: false
    }
  },
  created () {
    this.eventListeners = [
      navbarStatusChanged.on(this.onNavbarToggleRequested.bind(this))
    ]
  },
  mounted () {
    this.mountMap = true
  },
  destroyed () {
    this.eventListeners.forEach(listener => listener.unsubscribe())
  },
  methods: {
    ...mapActions({
      setSelectedDevice: 'devices/setSelectedDevice',
      setCenter: 'map/setCenter',
      setFollowId: 'map/setFollowId'
    }),
    onNavbarToggleRequested () {
      this.menuOpened = !this.menuOpened
    },
    toggleMapMenu () {
      navbarStatusChanged.dispatch()
    },
    deviceMarkerClicked (device) {
      if (device.lat && device.lng) {
        this.setCenter([device.lat, device.lng])
      }
      this.setSelectedDevice(device)
    },
    userMoveStarted () {
      this.setFollowId(null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/sizes';
@import '~@/scss/variables/colors';

.home {
  width: 100%;
  height: 100%;
}

.body {
  position: relative;
}

.tabs-container {
  height: 100%;
  display: grid;
  grid-template-rows: 35px 1fr;
  // height: 100%;
  // overflow-y: scroll;

  @media only screen and (max-width: 900px) {
    grid-template-rows: var(--app-navbar) 1fr;
  }
}

.tabs-header {
  display: flex;
  align-items: center;
  position: relative;
}

.close-button {
  display: none;

  @media only screen and (max-width: 900px) {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--app-navbar);
  }
}

.tab-right-item {
  position: absolute;
  height: 100%;
  right: 0;
}

.tab-item {
  border-bottom: solid 2px transparent;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;

  &.active {
    border-bottom-color: $main-color;
  }
}

.tab-body {
  overflow-y: scroll;
}

.map-sidebar {
  height: 100%;
  width: 95%;
  max-width: 350px;

  @media only screen and (max-width: 900px) {
    box-shadow: 0px 0px 20px #00000045;
    background-color: var(--page-content-background);
    position: absolute;
    transform: translateX(-500px);
    transition: transform 100ms ease-in-out;
    top: 0;
    z-index: 1001;

    &.opened {
      transform: translateX(0);
    }
  }
}

</style>
