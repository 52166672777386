<template lang="pug">
div
  navbar
  .page-body
    app-menu.default-app-menu(:class='{ reduced: reducedMenu }')
    .page-content
      slot
</template>

<script>
import { mapActions, mapState } from 'vuex'
import updatesService from '@/services/updates.service'
import Menu from '@/components/Menu'
import Navbar from '@/components/Navbar'

export default {
  components: { AppMenu: Menu, Navbar },
  data () {
    return {
      interval: null
    }
  },
  created () {
    updatesService.start()
    this.loadPendingCommands()
    this.interval = setInterval(() => {
      const maxId = this.pendingCommands.reduce((maxId, command) => command.id > maxId ? command.id : maxId, 0)
      this.loadPendingCommands(maxId)
    }, 30000)
  },
  mounted () {
    // const backgrounds = [
    //   'https://img.wallpapersafari.com/desktop/1920/1080/79/73/TvuM20.jpg',
    //   'https://img.wallpapersafari.com/desktop/1280/1024/46/30/ObEGSN.jpg',
    //   'https://img.wallpapersafari.com/desktop/1920/1080/3/99/7cPhMl.jpg',
    //   'https://img.wallpapersafari.com/desktop/1920/1080/50/47/av7PrR.jpg',
    //   'http://ndesaintheme.com/themes/apricot/assets/img/bg5.jpg'
    // ]
    // const random = Math.floor(Math.random() * backgrounds.length)
    // document.body.style.backgroundImage = `url(${backgrounds[random]})`
  },
  beforeDestroy () {
    clearInterval(this.interval)
    updatesService.stop()
  },
  computed: {
    ...mapState({
      pendingCommands: state => state.commands.pendingCommands
    }),
    reducedMenu () {
      return this.$route.meta.reducedMenu
    }
  },
  methods: {
    ...mapActions({
      loadPendingCommands: 'commands/loadPendingCommands'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables/sizes';

body,
html {
  height: 100%;
  overflow: hidden;
}

.page-body {
  display: flex;
  height: calc(100vh - var(--app-navbar));
}

.default-app-menu {
  width: 300px;

  &.reduced {
    width: auto;
  }

  @media only screen and (max-width: 900px) {
    position: absolute;
    bottom: 0;
    top: var(--app-navbar);
    background: var(--dark-background);
    transform: translateX(-300px);
    z-index: 10001;

    &.opened {
      transform: translateX(0);
    }
  }
}

// .default-page {
//   // display: flex;
// }

.page-content {
  background: var(--page-content-background);
  flex: 1;
  overflow-y: auto;
  border-radius: $border-radius 0 0 $border-radius;
}
</style>
