import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowDown,
  faArrowUp,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCog,
  faEdit,
  faExclamation,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faFileDownload,
  faHandPaper,
  faImages,
  faLightbulb,
  faMinus,
  faPaperPlane,
  faPlus,
  faSignOutAlt,
  faSpinner,
  faStopwatch,
  faTag,
  faTimes,
  faTrash,
  faWindowMaximize,
  faMap,
  faMapMarker,
  faMapMarked,
  faUsers,
  faBoxes,
  faBolt,
  faFileInvoiceDollar,
  faBook,
  faShippingFast,
  faAngleLeft, // for buefy calendar component
  faAngleRight, // for buefy calendar component
  faTimesCircle, // for buefy calendar component
  faDownload,
  faRedo,
  faCheckDouble,
  faBell,
  faClock,
  faDrawPolygon,
  faTachometerAlt,
  faTruck,
  faChalkboardTeacher,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowDown,
  faArrowUp,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCog,
  faEdit,
  faExclamation,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faFileDownload,
  faHandPaper,
  faImages,
  faLightbulb,
  faMinus,
  faPaperPlane,
  faPlus,
  faSignOutAlt,
  faSpinner,
  faStopwatch,
  faTag,
  faTimes,
  faTrash,
  faWindowMaximize,
  faMap,
  faMapMarker,
  faMapMarked,
  faUsers,
  faBoxes,
  faBolt,
  faShippingFast,
  faFileInvoiceDollar,
  faBook,
  faAngleLeft,
  faAngleRight,
  faTimesCircle,
  faDownload,
  faRedo,
  faCheckDouble,
  faBell,
  faClock,
  faDrawPolygon,
  faTachometerAlt,
  faTruck,
  faChalkboardTeacher,
  faInfoCircle
)

Vue.component('icon', FontAwesomeIcon)
