<template lang="pug">
span
  indicator-button(
    title='Notificaciones'
    icon='bell'
    :unreaded='unreaded'
    @click='isNotificationsModalActive = true'
  )
  b-modal(v-model='isNotificationsModalActive' has-modal-card)
    .events-container
      .events-page-header
        button.seen-all-button(type='button' @click='markAllAsSeen' :disabled='markingAll')
          icon.ml-2(:icon='markingAll ? "spinner" : "check-double"')
        text-field.events-search(placeholder='Buscar...' v-model='query')
      .event-container(v-for='event in filteredEvents' :class='{ unread: !event.seen }')
        .event-header
          span.event-message {{ event.message }}
          span.device-name &#x2022; {{ event.deviceName }}
        span.date {{ event.date | datetime }} &#x2022; {{ event.date | dateDistance }}
        button.seen-button(type='button' @click='markAsSeen(event)' :disabled='event.loading' :class='{ loading: event.loading }' v-if='!event.seen')
          icon.ml-2(:icon='event.loading ? "spinner" : "check"')
      .has-text-centered.p-4
        icon.spinner(icon='spinner' v-if='loading')
        a(@click='getMoreEvents' v-else) Cargar más eventos
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DevicesList from '@/components/home/DevicesList'
import IndicatorButton from '@/components/shared/IndicatorButton'
import eventsService from '@/services/events.service'

export default {
  components: {
    DevicesList,
    IndicatorButton
  },
  data () {
    return {
      isNotificationsModalActive: false,
      query: '',
      markingAll: false
    }
  },
  created () {
    this.getEvents()
  },
  computed: {
    ...mapState({
      loading: state => state.notifications.loading,
      error: state => state.notifications.error,
      events: state => state.notifications.events
    }),
    unreaded () {
      return this.events.filter(event => !event.seen).length
    },
    filteredEvents () {
      if (!this.query) return this.events
      return this.events.filter(event => {
        if (event.message.toLowerCase().indexOf(this.query.toLowerCase()) >= 0) return true
        if (event.deviceName.toLowerCase().indexOf(this.query.toLowerCase()) >= 0) return true
        return false
      })
    }
  },
  methods: {
    ...mapActions({
      getEvents: 'notifications/getEvents',
      getMoreEvents: 'notifications/getMoreEvents'
    }),
    async markAsSeen (event) {
      event.loading = true
      const result = await eventsService.markAsSeen([event.id])
      if (result) {
        event.seen = true
      }
      event.loading = false
    },
    async markAllAsSeen () {
      this.markingAll = true
      const events = this.filteredEvents.filter(event => !event.seen)
      const eventIds = events.map(event => event.id)
      const result = await eventsService.markAsSeen(eventIds)
      if (result) {
        events.forEach(event => {
          event.seen = true
        })
      }
      this.markingAll = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.events-page-header {
  display: flex;
}

.seen-all-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding-right: 10px;

  &:hover {
    background: rgba(black, 0.1);
    cursor: pointer;
  }
}

.events-search {
  width: 100%;
}

.events-container {
  background: white;
  color: $text-color;
  font-size: 0.9em;
  overflow-y: auto;
  min-width: 600px;
  max-height: 95vh;

  @media only screen and (max-width: 600px) {
    min-width: inherit;
  }
}

.event-container {
  margin-bottom: 1px;
  padding: 0 5px;
  position: relative;

  &.unread {
    background: #ededed;
  }

  &:hover .seen-button,
  .loading {
    display: inline-block;
    cursor: pointer;
  }
}

.event-message {
  font-weight: bold;
  margin-right: 5px;
}

.seen-button {
  position: absolute;
  right: 0;
  border: none;
  height: 100%;
  top: 0;
  display: none;
}
</style>
