<template lang="pug">
l-popup
  .flex-column
    span.popup-title.mb-1(v-if='date') {{ date | datetime }}
    .flex
      span.popup-label Velocidad:
      span.ml-1 {{ speed ? ` ${speed}Km/h ` : ' Sin info' }}
      span.popup-label.ml-2(v-if='battery && battery >= 0') | Batería:
      span.ml-1(v-if='battery && battery >= 0') {{`${battery}%`}}
    span(v-if='isStopped')
      | {{ `Se detuvo ${stoppedMinutes} minutos aquí` }}
      //- TODO: if time > 60 min then show hours
    span(v-if='nearbyLocationName')
      | {{ `Cerca de ${nearbyLocationName} (${getDistanceInMeters(nearbyLocationDistance)})` }}
</template>

<script>
import { LPopup } from 'vue2-leaflet'

export default {
  components: { LPopup },
  props: {
    date: { type: String, default: '' },
    speed: { type: String, default: '' },
    battery: { type: String, default: '' },
    stoppedMinutes: { type: String, default: '' },
    nearbyLocationName: { type: String, default: '' },
    nearbyLocationDistance: { type: String, default: '' },
    isStopped: { type: Boolean, default: false }
  },
  methods: {
    getDistanceInMeters (distance) {
      // TODO: review calculate distance in meter
      // mas de 100 km
      const distanceInMeters = (Number(distance) / 1000).toFixed(0)
      const distanceFormat = new Intl.NumberFormat('es-CO')
      return distance ? `a ${distanceFormat.format(distanceInMeters)} metros` : 'Sin info'
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

.popup-label {
  font-size: 12px;
  font-weight: 700;
}
</style>
