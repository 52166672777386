<template lang="pug">
#app
  div.has-background-danger.has-text-white.text-center.is-flex.is-justify-content-center(v-if='connectionError') Intentando conectarse...
  component.app-body(:is='layout')
    router-view
</template>

<script>
import { addExpiredSessionEventListener } from './services'
import { onConnectionFails } from '@/services'

export default {
  data () {
    return { interval: null }
  },
  created () {
    addExpiredSessionEventListener(() => {
      this.$router.push('login')
    })
    onConnectionFails(error => {
      this.$store.dispatch('connection/notifyError', error)
    })
    document.title = window.location.hostname
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'public') + '-layout'
    },
    connectionError () {
      return this.$store.state.connection.error
    }
  }
}
</script>

<style lang="scss">
@import 'scss/application';

#app {
  color: $text-color;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.app-body {
  flex: 1;
}
</style>
