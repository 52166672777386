import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import local from '../database/local-storage'
import { setToken } from '../services'
import { ewsDashboard,
  home,
  contractsList,
  contractDetail,
  invoicesList,
  reportInOutZones,
  reportSpeed,
  invoiceDetail,
  pendingInvoices,
  withoutPermissions,
  login,
  reportTypes,
  createInvoice,
  inpointSchedules,
  inPoint,
  inPointRegisterLocation,
  inpointVisits,
  locations,
  inpointSchedule,
  inpointNewSchedule,
  shippingShipments,
  clientsList,
  clientDetail,
  command,
  adminDevices,
  adminDeviceDetails,
  map,
  rulesList,
  rulesZoneCreate,
  rulesZoneEdit,
  rulesOdometerCreate,
  rulesOdometerEdit,
  rulesTimeCreate,
  rulesTimeEdit,
  rulesUsageCreate,
  rulesUsageEdit
} from './routes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { path: '/map' }
  },
  home,
  locations,
  map,
  login,
  adminDevices,
  adminDeviceDetails,
  {
    path: '/resume',
    name: 'resume',
    component: () => import(/* webpackChunkName: "resume" */ '../views/Resume.vue'),
    meta: { requiresAuth: true, layout: 'default' }
  },
  ewsDashboard,
  {
    path: '/ews/admin-variable/:id',
    name: 'ews-admin-variable',
    component: () => import(/* webpackChunkName: "ews-admin-variable" */ '../views/ews/AdminVariable.vue'),
    meta: { requiresAuth: true, layout: 'default' }
  },
  {
    path: '/ews/station/:id',
    name: 'ews-station',
    component: () => import(/* webpackChunkName: "ews-station" */ '../views/ews/Station.vue'),
    meta: { requiresAuth: true, layout: 'default' }
  },
  contractsList,
  contractDetail,
  pendingInvoices,
  invoicesList,
  createInvoice,
  invoiceDetail,
  reportInOutZones,
  reportSpeed,
  withoutPermissions,
  reportTypes,
  inpointSchedules,
  inpointSchedule,
  inpointNewSchedule,
  inpointVisits,
  inPoint,
  inPointRegisterLocation,
  shippingShipments,
  clientsList,
  clientDetail,
  command,
  rulesList,
  rulesZoneCreate,
  rulesZoneEdit,
  rulesOdometerCreate,
  rulesOdometerEdit,
  rulesTimeCreate,
  rulesTimeEdit,
  rulesUsageCreate,
  rulesUsageEdit
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const canEnterAuth = async (to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth)) return true

  const token = await local.getItem('token')
  if (!token) return false

  if (store.state.auth.user) return true

  await setToken(token)

  const result = await store.dispatch('auth/getMe')
  if (result) return true
  return false
}

const canEnterPermissions = async (to, from, next) => {
  const someCanEnter = to.matched.some(record => {
    const permissionId = record.meta && record.meta.permission
    if (!permissionId) return true

    const user = store.state.auth && store.state.auth.user
    if (!user) return false

    return user.permissionsArray.includes(permissionId)
  })
  return someCanEnter
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.length === 0) {
    next({ name: home.name, params: { nextUrl: to.fullPath } })
    return
  }
  if (!await canEnterAuth(to, from, next)) {
    next({ name: login.name, params: { nextUrl: to.fullPath } })
    return
  }
  if (!await canEnterPermissions(to, from, next)) {
    next({ path: withoutPermissions.name, params: { nextUrl: to.fullPath } })
    return
  }
  next()
})

export default router
