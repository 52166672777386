import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.module'
import devices from './modules/devices.module'
import clients from './modules/clients.module'
import map from './modules/map.module'
import commands from './modules/commands.module'
import connection from './modules/connection.module'
import nfc from './modules/nfc.module'
import reports from './modules/reports.module'
import zones from './modules/zones.module'
import locations from './modules/locations.module'
import notifications from './modules/notifications.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    map,
    devices,
    clients,
    commands,
    connection,
    nfc,
    reports,
    zones,
    locations,
    notifications
  },
  state: {},
  mutations: {},
  actions: {}
})
